import { PDFDocument, rgb } from "pdf-lib";

export const handlePrint = async (selectedApplication) => {
    if (!selectedApplication) return;

    console.log("Selected Application:", selectedApplication);

    const pdfDoc = await PDFDocument.create();
    const [width, height] = [700, 300];
    const page1 = pdfDoc.addPage([width, height]);
    const page2 = pdfDoc.addPage([width, height]);
    const page3 = pdfDoc.addPage([width, height]);
    const page4 = pdfDoc.addPage([width, height]);
    const page5 = pdfDoc.addPage([width, height]);

    const helveticaFont = await pdfDoc.embedFont("Helvetica");
    const textColor = rgb(0, 0, 0);
    const seaBlueColor = rgb(0, 105 / 255, 148 / 255);
    const greenColor = rgb(0, 0.5, 0);
    const grayColor = rgb(0.5, 0.5, 0.5);
    const borderWidth = 0.5;

    const drawText = (page, text, x, y, size, align, color) => {
      const textWidth = helveticaFont.widthOfTextAtSize(text, size);
      let xPosition;
      if (align === "left") {
        xPosition = x;
      } else if (align === "right") {
        xPosition = width - textWidth - x;
      } else {
        xPosition = (width - textWidth) / 2;
      }
      if (color === greenColor && align === "left") {
        xPosition += 300;
      }
      page.drawText(text.toString(), {
        x: xPosition,
        y: y,
        size: size,
        font: helveticaFont,
        color: color,
      });
    };

    const borderX = 25;
    const borderY = 25;
    const dataWidth = width - 2 * borderX;
    const dataHeight = height - 2 * borderY;

    page1.drawRectangle({
      x: borderX,
      y: borderY,
      width: dataWidth,
      height: dataHeight,
      borderColor: grayColor,
      borderWidth: borderWidth,
    });
    page2.drawRectangle({
      x: borderX,
      y: borderY,
      width: dataWidth,
      height: dataHeight,
      borderColor: grayColor,
      borderWidth: borderWidth,
    });
    page3.drawRectangle({
      x: borderX,
      y: borderY,
      width: dataWidth,
      height: dataHeight,
      borderColor: grayColor,
      borderWidth: borderWidth,
    });
    page4.drawRectangle({
      x: borderX,
      y: borderY,
      width: dataWidth,
      height: dataHeight,
      borderColor: grayColor,
      borderWidth: borderWidth,
    });
    page5.drawRectangle({
      x: borderX,
      y: borderY,
      width: dataWidth,
      height: dataHeight,
      borderColor: grayColor,
      borderWidth: borderWidth,
    });

    drawText(page1, "Ihre Kontaktdaten", 50, 250, 18, "left", seaBlueColor);
    drawText(page1, `Name:`, 50, 220, 12, "left", textColor);
    drawText(
      page1,
      `${selectedApplication.firstname} ${selectedApplication.lastname}`,
      120,
      220,
      12,
      "left",
      greenColor
    );
    drawText(page1, `Email:`, 50, 200, 12, "left", textColor);
    drawText(
      page1,
      `${selectedApplication.email}`,
      120,
      200,
      12,
      "left",
      greenColor
    );
    drawText(page1, `Stadt:`, 50, 180, 12, "left", textColor);
    drawText(
      page1,
      `${selectedApplication.city}`,
      120,
      180,
      12,
      "left",
      greenColor
    );
    drawText(page1, `Krankenkasse:`, 50, 160, 12, "left", textColor);
    drawText(
      page1,
      `${selectedApplication.health_insurance}`,
      120,
      160,
      12,
      "left",
      greenColor
    );

    drawText(
      page2,
      "Aktuelle Krankenkasse",
      50,
      250,
      18,
      "left",
      seaBlueColor
    );
    drawText(page2, `Name:`, 50, 220, 12, "left", textColor);
    drawText(
      page2,
      `${selectedApplication.current_health_insurance}`,
      120,
      220,
      12,
      "left",
      greenColor
    );
    drawText(page2, `Versicherungsart:`, 50, 200, 12, "left", textColor);
    drawText(
      page2,
      `${selectedApplication.current_insurance_type}`,
      120,
      200,
      12,
      "left",
      greenColor
    );
    drawText(page2, `Versicherungs Situation:`, 50, 180, 12, "left", textColor);
    drawText(
      page2,
      `${selectedApplication.insurance_situation}`,
      120,
      180,
      12,
      "left",
      greenColor
    );
    drawText(page2, `Insurance Type:`, 50, 160, 12, "left", textColor);
    drawText(
      page2,
      `${selectedApplication.min_insurance_time}`,
      120,
      160,
      12,
      "left",
      greenColor
    );

    drawText(
      page3,
      "Zusätzliche Informationen",
      50,
      250,
      18,
      "left",
      seaBlueColor
    );
    drawText(page3, `Kinder:`, 50, 220, 12, "left", textColor);
    drawText(
      page3,
      `${selectedApplication.children || "-"}`,
      120,
      220,
      12,
      "left",
      greenColor
    );
    drawText(page3, `Ehepartner:`, 50, 200, 12, "left", textColor);
    drawText(
      page3,
      `${selectedApplication.insure_spouse || "-"}`,
      120,
      200,
      12,
      "left",
      greenColor
    );
    drawText(page3, `Einkommen Selbstständig`, 50, 180, 12, "left", textColor);
    drawText(
      page3,
      `${selectedApplication.selfemployed_income || "-"}`,
      120,
      180,
      12,
      "left",
      greenColor
    );
    drawText(page3, `Pension Status:`, 50, 160, 12, "left", textColor);
    drawText(
      page3,
      `${selectedApplication.has_pension || "-"}`,
      120,
      160,
      12,
      "left",
      greenColor
    );
    drawText(page3, `Versicherungsstatus:`, 50, 140, 12, "left", textColor);
    drawText(
      page3,
      `${selectedApplication.freed_from_health_insurance || "-"}`,
      120,
      140,
      12,
      "left",
      greenColor
    );

    drawText(page4, "Arbeitgeber Info", 50, 250, 18, "left", seaBlueColor);
    drawText(page4, `Einkommen:`, 50, 230, 12, "left", textColor);
    drawText(
      page4,
      `${selectedApplication.income || "-"}`,
      120,
      230,
      12,
      "left",
      greenColor
    );
    drawText(page4, `Firma:`, 50, 210, 12, "left", textColor);
    drawText(
      page4,
      `${selectedApplication.company_name || "-"}`,
      120,
      210,
      12,
      "left",
      greenColor
    );
    drawText(page4, `Postleitzahl:`, 50, 190, 12, "left", textColor);
    drawText(
      page4,
      `${selectedApplication.zip}`,
      120,
      190,
      12,
      "left",
      greenColor
    );
    drawText(page4, `Stadt:`, 50, 170, 12, "left", textColor);
    drawText(
      page4,
      `${selectedApplication.city}`,
      120,
      170,
      12,
      "left",
      greenColor
    );
    drawText(page4, `Straße:`, 50, 150, 12, "left", textColor);
    drawText(
      page4,
      `${selectedApplication.street}`,
      120,
      150,
      12,
      "left",
      greenColor
    );
    drawText(page4, `Hausnummer:`, 50, 130, 12, "left", textColor);
    drawText(
      page4,
      `${selectedApplication.housenumber}`,
      120,
      130,
      12,
      "left",
      greenColor
    );
    drawText(page4, `Arbeitgeberwechsel:`, 50, 110, 12, "left", textColor);
    drawText(
      page4,
      `${selectedApplication.change_of_employement || "-"}`,
      120,
      110,
      12,
      "left",
      greenColor
    );
    drawText(page4, `Anstellungsdauer:`, 50, 90, 12, "left", textColor);
    drawText(
      page4,
      `${selectedApplication.min_employement_duration || "-"}`,
      120,
      90,
      12,
      "left",
      greenColor
    );
    drawText(
      page4,
      `Verhätlnis zum Arbeitsgeber:`,
      50,
      70,
      12,
      "left",
      textColor
    );
    drawText(
      page4,
      `${selectedApplication.related_to_employer || "-"}`,
      120,
      70,
      12,
      "left",
      greenColor
    );
    drawText(page4, `Unternehmensanteile:`, 50, 50, 12, "left", textColor);
    drawText(
      page4,
      `${selectedApplication.holding_company_shares || "-"}`,
      120,
      50,
      12,
      "left",
      greenColor
    );
    drawText(page4, `Mehrere Arbeitgeber:`, 50, 30, 12, "left", textColor);
    drawText(
      page4,
      `${selectedApplication.multiple_employers || "-"}`,
      120,
      30,
      12,
      "left",
      greenColor
    );

    drawText(page5, "Geburtsinformationen", 50, 250, 18, "left", seaBlueColor);
    drawText(page5, `Geburtsname:`, 50, 220, 12, "left", textColor);
    drawText(
      page5,
      `${selectedApplication.firstname} ${selectedApplication.lastname}`,
      120,
      220,
      12,
      "left",
      greenColor
    );
    drawText(page5, `Geburtsort:`, 50, 200, 12, "left", textColor);
    drawText(
      page5,
      `${selectedApplication.place_of_birth || "-"}`,
      120,
      200,
      12,
      "left",
      greenColor
    );
    drawText(page5, `Geburtsland:`, 50, 180, 12, "left", textColor);
    drawText(
      page5,
      `${selectedApplication.birth_country || "-"}`,
      120,
      180,
      12,
      "left",
      greenColor
    );
    drawText(page5, `Nationalität:`, 50, 160, 12, "left", textColor);
    drawText(
      page5,
      `${selectedApplication.nationality || "-"}`,
      120,
      160,
      12,
      "left",
      greenColor
    );

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${selectedApplication.firstname}_${selectedApplication.lastname}_summary.pdf`;
    link.click();
    window.open(link.href, "_blank");
  };
