import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BiPrinter } from 'react-icons/bi';

const ApplicationDataGrid = ({
  applications,
  loading,
  columns,
  modalOpen,
  selectedApplication,
  handleOpenModal,
  handleCloseModal,
  onSelectionModelChange,
  isApplicationOpen,
  handlePrint,
  renderRedDot,
}) => {
  if (loading) return <div>Lade...</div>;

  return (
    <>
      <h3 className="text-2xl text-center text-gray-800 mb-4">Anträge</h3>
      <div style={{ width: '100%' }}>
        <DataGrid
          autoHeight={true}
          rows={applications}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          checkboxSelection
          onSelectionModelChange={onSelectionModelChange}
        />
      </div>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Stack spacing={2}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'white',
              boxShadow: 24,
              p: 4,
            }}
          >
            {selectedApplication && (
              <>
                <Typography
                  variant="h6"
                  component="h2"
                  id="modal-title"
                  style={{
                    marginBottom: '10px',
                    marginTop: '20px',
                    textAlign: 'center',
                  }}
                >
                  {`${selectedApplication.firstname} ${selectedApplication.lastname}`} Details
                </Typography>
                <Divider style={{ marginBottom: '10px' }} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Typography
                      variant="body1"
                      id="modal-description"
                      style={{
                        marginBottom: '10px',
                        textAlign: 'left',
                        marginRight: '10px',
                      }}
                    >
                      <strong>Name:</strong>
                    </Typography>
                    <Typography
                      variant="body1"
                      id="modal-description"
                      style={{
                        marginBottom: '10px',
                        textAlign: 'right',
                        color: 'green',
                      }}
                    >
                      {`${selectedApplication.firstname} ${selectedApplication.lastname}`}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Typography
                      variant="body1"
                      id="modal-description"
                      style={{
                        marginBottom: '10px',
                        textAlign: 'left',
                        marginRight: '10px',
                      }}
                    >
                      <strong>Email:</strong>
                    </Typography>
                    <Typography
                      variant="body1"
                      id="modal-description"
                      style={{
                        marginBottom: '10px',
                        textAlign: 'right',
                        color: 'green',
                      }}
                    >
                      {selectedApplication.email}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Typography
                      variant="body1"
                      id="modal-description"
                      style={{
                        marginBottom: '10px',
                        textAlign: 'left',
                        marginRight: '10px',
                      }}
                    >
                      <strong>Vorherige Krankenkasse:</strong>
                    </Typography>
                    <Typography
                      variant="body1"
                      id="modal-description"
                      style={{
                        marginBottom: '10px',
                        textAlign: 'right',
                        color: 'green',
                      }}
                    >
                      {selectedApplication.current_health_insurance}
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '20px',
                  }}
                >
                  <button
                    onClick={() => handlePrint(selectedApplication)}
                    className={`bg-${isApplicationOpen ? 'blue' : 'gray'}-500 
                              hover:bg-transparent 
                              text-white hover:text-black 
                              border border-${
                                isApplicationOpen ? 'blue' : 'gray'
                              }-500 
                              hover:border-transparent 
                              rounded outline-none focus:outline-none 
                              transition duration-300 ease-in-out p-1 text-sm`}
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      marginBottom: '10px',
                      marginRight: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'nowrap'
                    }}
                  >
                    <BiPrinter
                      className="text-lg"
                      color="black"
                      style={{ marginRight: '5px' }}
                    />
                    <span style={{ color: 'black' }}>Anzeigen</span>
                  </button>
                </div>
              </>
            )}
            {!selectedApplication && <p>Keine Anträge ausgewählt</p>}
          </Box>
        </Stack>
      </Modal>
    </>
  );
};

export default ApplicationDataGrid;
