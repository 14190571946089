import React, { useState, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const ForgotPassword = () => {
  const { isLoading, setIsLoading } = useContext(UserContext);
  const [step, setStep] = useState(1); 
  const [formData, setFormData] = useState({ email: "", password: "", resetCode: "" });
  const navigate = useNavigate(); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/reset`, { email: formData.email });
      toast.success("Reset code sent to your email.");
      setStep(2);  
    } catch (error) {
      toast.error("Failed to send reset code: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetCodeSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/verify-reset-code`, {
        email: formData.email,
        resetCode: formData.resetCode
      });
      toast.success("Reset code verified. You can now reset your password.");
      setStep(3);  
    } catch (error) {
      toast.error("Invalid reset code: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordResetSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/password-reset`, {
        email: formData.email,
        resetCode: formData.resetCode,
        newPassword: formData.password
      });
      toast.success("Password successfully reset.");
      navigate("/login");  
    } catch (error) {
      console.error("Error during password reset:", error.response); 
      toast.error("Failed to reset password: " + (error.response?.data?.message || error.message));
    } finally {
      setIsLoading(false);
    }
  };  

  return (
    <div className="min-w-screen min-h-screen bg-white flex items-center justify-center px-5 py-5">
      <ToastContainer />
      <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-2xl w-full overflow-hidden" style={{ maxWidth: "1000px" }}>
        <div className="text-center mb-10 mt-10">
          <h2 className="font-bold text-3xl text-gray-900">
            {step === 1 ? "Passwort zurücksetzen" : step === 2 ? "Reset code eingeben" : "Passwort zurücksetzen"}
          </h2>
        </div>
        <div className="flex justify-center w-full py-10">
          <form 
            onSubmit={step === 1 ? handleEmailSubmit : step === 2 ? handleResetCodeSubmit : handlePasswordResetSubmit} 
            className="w-full md:w-1/2"
          >
            {step === 1 && (
              <div className="mb-5">
                <input
                  type="email"
                  name="email"
                  className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                  placeholder="Emailadresse eingeben"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            )}

            {step === 2 && (
              <div className="mb-5">
                <input
                  type="text"
                  name="resetCode"
                  className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                  placeholder="Enter reset code"
                  value={formData.resetCode}
                  onChange={handleChange}
                  required
                />
              </div>
            )}

            {step === 3 && (
              <div className="mb-5">
                <input
                  type="password"
                  name="password"
                  className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                  placeholder="Enter new password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </div>
            )}

            <div className="flex justify-center mb-5">
              <button
                type="submit"
                className="bg-[#0098D6] hover:bg-[#0098D6] focus:bg-[#0098D6] text-white rounded-lg px-4 py-2 font-semibold"
                disabled={isLoading}
              >
                {isLoading ? "Sende..." : step === 1 ? "Reset Code senden" : step === 2 ? "Verify Reset Code" : "Reset Password"}
              </button>
            </div>
          </form>
        </div>
        <div className="flex justify-center mb-5">
          <button
            onClick={() => navigate("/login")}  
            className="text-blue-500 hover:underline"
          >
            Zurück zum Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
