import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser } from '../context/UserContext';
import ArtificialForm from './Questions/artificial/Artificial';
import CancerForm from './Questions/cancer/Cancer';
import MedicineForm from './Questions/medicine/Medicine';
import SportsForm from './Questions/sports/Sports';
import TeethForm from './Questions/teeth/Teeth';
import ServiceForm from './Questions/service/Service';
import VaccineForm from './Questions/vaccine/Vaccine';

const SpecificQuestion = () => {
  const { user, questionnaireData, updateQuestionnaireData, submitQuestionnaire } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await submitQuestionnaire(user.userId, questionnaireData);
      toast.success('Daten zu Ihren Serviceleistungen wurden aktualisiert!');
      setExpanded(false);
    } catch (error) {
      toast.error('Failed to submit data. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="h-full bg-white">
      <ToastContainer />
      <h1 className="text-2xl text-center my-4" style={{ color: '#4e5859', fontSize: '35px' }}>Fragen zu Serviceleistungen</h1>
      <form onSubmit={handleSubmit} className="flex justify-center p-5">
        <div className="max-w-4xl w-full bg-white shadow-lg rounded-xl border-gray-300">
          {[
            { title: 'Fragen Zähne', component: TeethForm, key: 'teeth' },
            { title: 'Impfungen', component: VaccineForm, key: 'vaccine' },
            { title: 'Erweiterte Krebsvorsorge', component: CancerForm, key: 'cancer' },
            { title: 'Alternative Heilmethoden', component: MedicineForm, key: 'medicine' },
            { title: 'Sport und Bewegung', component: SportsForm, key: 'sports' },
            { title: 'Service', component: ServiceForm, key: 'service' },
            { title: 'Familie', component: ArtificialForm, key: 'artificial' }
          ].map((section, index) => (
            <Accordion expanded={expanded === `panel${index}`} onChange={handleAccordionChange(`panel${index}`)} key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <h2 style={{ color: '#4e5859', fontSize: '28px' }}>{section.title}</h2>
              </AccordionSummary>
              <AccordionDetails>
                <section.component
                  data={questionnaireData[section.key]}
                  updateData={(id, key, value) => updateQuestionnaireData(section.key, id, key, value)}
                />
              </AccordionDetails>
            </Accordion>
          ))}
          <div className="text-center mt-4">
            <button
              type="submit"
              className="px-4 py-2 text-white bg-[#0098D6] hover:bg-[#0098D6] focus:bg-[#0098D6] rounded-md focus:outline-none mb-4"
              disabled={isLoading}
            >
              {isLoading ? "Sende..." : "Daten senden"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SpecificQuestion;
